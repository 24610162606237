.ipo_detail_logo{
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
}

.detail-card-inner .ipo_detail_table_title{
    padding-top: 50px;
    font-size: 20px;
    font-Weight: 500;
    line-height: 140%;
}

.premium{
    max-width: max-content;
    color: green;
    background: #00800014;
    border-radius: 5px;
    padding: 5px 20px !important;
    font-weight: 700 !important;
    margin-top: 8px;
}

.ipo_detail_table_title_head{
    font-size: 20px;
    font-Weight: 500;
    line-height: 140%;
}

.riskFector span{
    color: #6B7280 !important;
    font-size: 14px !important;
    padding-left: 5px !important;
}

/* .text_color{
    color: #6B7280 !important;
    font-size: 14px !important;
    word-wrap: break-word !important;
    word-break: break-word !important
} */

@media(max-width: 768px){
    .ipo_detail_logo{
        min-height: 100px;
    }

    .heading-group.table_1_heading{
        flex-direction: column;
    }

    .card-header:has(.heading-group.table_1_heading){
        padding-top: 0 !important;
        padding-right: 0;
    }

    .heading-group{
        padding: 10px 0;
    }

    .detail-card-inner{
        /* padding-left: 10px;
        padding-right: 10px; */
    }

    .detail-card-inner .ipo_detail_table_title{
        padding-top: 22px;
        font-size: 16px;
        padding-bottom: 0;
    }
    
}