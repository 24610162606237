.newspage
{
    padding-top: 50px;
}
.news-pagination-button {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    padding: 12px;
    transition: all .3s ease;
}

@media(max-width: 768px){
    .newspage {
      padding-top: 30px;
    }
    
  }