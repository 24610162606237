.brokerPage {
  padding-top: 50px;
}

@media(max-width: 768px){
  .brokerPage {
    padding-top: 30px;
  }
  
}

@media(max-width: 465px){
  .broker_detail_top_btn{
    margin-left: 0;
  }
  div:has(>a>.broker_detail_top_btn){
    width: 100%;
  }
}

@media(max-width: 400px){
 .charges_div{
  width: 100%;
  padding-right: 0;
  padding-bottom: 11px;

 }
}

/* .line_clamp_news_discription {
    display: -webkit-box; 
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
 */
