.banner_section {
  padding: 103px 0 40px 0;
  overflow-x: hidden;
}

.banner_section::before {
  content: "";
  position: absolute;
  top: -10%;
  left: -5%;
  background: url("../images/flower_bg.webp") no-repeat;
  width: 278.27px;
  height: 295px;
  z-index: -2;
}
.banner_section::after {
  content: "";
  position: absolute;
  top: -10%;
  right: -6.8%;
  background: url("../images/flower_bg.webp") no-repeat;
  width: 278.27px;
  height: 295px;
  z-index: -2;
}

.about_us_title.common_heading_h2 {
  padding-top: 0;
  padding-bottom: 0;
}

.about_us_title.common_heading_h2.ipoTrend_title {
  padding-bottom: 30px;
}

.about_us_page {
  background: rgba(4, 56, 115, 0.4);
  min-height: 430px;
}

.common_padding:has(.about_us_title) {
  padding-top: 40px;
}

.about_us_page::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/about_us/about_us\ \(2\).webp") no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
}

.about_us_text h2.common_heading_h2.about_us_title {
  font-size: 60px;
  font-weight: 700;
  line-height: 87.14px;
  letter-spacing: -0.02em;
  color: #fff;
  padding-top: 109px;
  text-align: center;
}

.ipo_trend_back::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: #043873;
  z-index: -1;
  border-radius: 13px;
}

.ipo_trend_box {
  box-shadow: 10px 0px 18px -5px #dddddd;
  background: #ffffff;
  padding: 15px 25px;
  border-radius: 12px;
  max-width: 507px;
  min-width: 507px;
}

.ipo_trend_box p {
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #555555;
}

.ipo_trend_box h3 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 10px;
}

.groupExperts {
  min-height: 250px;
  background: #f6f6f6;
}

.expert_part {
  left: 50%;
  transform: translateX(-50%);
}

.common_heading_h2.about_us_title {
  font-size: 35px;
  line-height: 120%;
}
.expert_1 {
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  border-radius: 50%;
  object-fit: cover;
}

.groupExperts p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #212529;
}

.groupExperts_2 {
  position: relative;
  top: -50px;
}

.groupExperts_2 svg,
.groupExperts_2 svg path {
  color: #fff;
  fill: #fff;
}

.groupExperts_2 {
  background: #043873;
}

.groupExperts_2 p {
  color: #fff;
}

.groupExperts_sec {
  padding-top: 80px;
  gap: 3%;
}
@media (max-width: 1220px) {
  .common_heading_h2.about_us_title {
    font-size: 27px;
  }
}

@media (max-width: 1140px) {
  .ipo_trend_box {
    max-width: 400px;
    min-width: 400px;
  }
}

@media (max-width: 1024px) {
  .ipo_trend_box h3 {
    font-size: 18px;
  }

  .groupExperts_sec {
    padding-top: 70px;
  }
  .ipo_trend_box {
    max-width: 300px;
    min-width: 300px;
  }
  .about_us_page {
    min-height: 300px;
  }

  .about_us_text .common_heading_h2.about_us_title {
    padding: 50px 0;
    font-size: 40px;
  }
  .banner_section {
    padding: 80px 0 40px 0;
  }

  .banner_section::before,
  .banner_section::after {
    width: 188.658px;
    height: 200px;
    background-size: 100% 100%;
  }

  .groupExperts {
    min-height: 200px;
  }

  .expert_1 {
    min-width: 70px;
    max-width: 70px;
    min-height: 70px;
    max-height: 70px;
  }
}

@media (max-width: 767px) {
  .ipo_trend_box h3 {
    font-size: 16px;
  }
  .groupExperts_2 {
    top: 0;
  }
  .about_us_text h2.common_heading_h2.about_us_title {
    font-size: 40px;
  }


  .groupExperts_sec {
    padding-top: 20px;
  }

  .groupExperts {
    min-height: max-content;
    margin-bottom: 70px;
  }
  
  .groupExperts:last-child {
    margin-bottom: 50px;
  }

  /* .common_heading_h2.about_us_title {
    font-size: 32px;
  } */

  .about_us_title.common_heading_h2.ipoTrend_title {
    padding-bottom: 20px;
}

  .ipo_trend_box {
    max-width: 90%;
    min-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .ipo_trend_back {
    margin-bottom: 50px;
    margin-top: 30px;
  }

  .ipo_trend_bull {
    max-width: 500px;
    margin: 0 auto;
  }

  .about_us_page {
    min-height: max-content;
  }

  .about_us_text .common_heading_h2.about_us_title {
    padding: 50px 0 !important;
    font-size: 35px;
  }

  .banner_section {
    padding: 40px 0 0 0;
    overflow-y: hidden;
  }
  .banner_section::before,
  .banner_section::after {
    width: 120px;
    height: 127.14px;
    top: -12%;
  }
  .banner_section::before {
    left: -38px;
  }
  .banner_section::after {
    right: -54px;
  }
}
