@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Black.ttf") format("ttf");
  font-weight: 900;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-ExtraBold.ttf") format("ttf");
  font-weight: 800;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Bold.ttf") format("ttf");
  font-weight: 700;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Light.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-ExtraLight.ttf") format("ttf");
  font-weight: 200;
}

@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins-Thin.ttf") format("ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Black.ttf") format("ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraLight.ttf") format("ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Thin.ttf") format("ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_Black.ttf") format("ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_ExtraBold.ttf") format("ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_Bold.ttf") format("ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_SemiBold.ttf") format("ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_Medium.ttf") format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_Regular.ttf") format("ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_Light.ttf") format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_ExtraLight.ttf") format("ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter_Thin.ttf") format("ttf");
  font-weight: 100;
}

* {
  /* font-family: 'Inter', sans-serif; */
  font-family: 'poppins', sans-serif;
  box-sizing: border-box;
}

body {
  font-weight: 400;
  line-height: 1.5;
  font-style: normal;
  font-display: swap;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

p,
span,
div {
  font-weight: 400;
  font-size: 14px;
  /* font-size: 18px; */
  line-height: 21px;
}

:root {
  --primary-color: #043873;
  --secondary-color: #2ecc71;
  --navbar-color: #fff;
  --navbar-mobile-color: #0438973;
  --navbar_list-font-size: 18px;
  --navbar_list-line-height: 28.8px;
  --navbar-button-font-size: 18px;
  --navbar-button-background: #4f9cf9;
  --navbar-button-line-height: 23px;
  --navbar-button-letter-spacing: -0.02em;
  --navbar-submenu-bg-color: #fff;
  --mobile-navbar-bg-color: #fff;
  --h2-font-size: 57px;
  --h2-line-height: 65.45px;
  --h2-letter-spacing: -0.02em;
  --h2-color: #fff;
  --banner_text-color: #fff;
  --banner_text-font-size: 18px;
  --banner_text-line-height: 30px;
  --banner_text-letter-spacing: -0.02em;
  --common_heading_h2-font-size: 64px;
  --common_heading_h2-line-height: 87.14px;
  --common_heading_h2-letter-spacing: -0.02em;
  --ipo-head-font-size: 20px;
  --ipo-head-line-height: 26.6px;
  --ipo-head-letter-spacing: -0.10000000149011612px;
  --theme-color-1: #0b1a46;
  --font-weight-500: 500;
  --font-weight-400: 400;
  --theme-color-2: #e5e6f2 !important;
  --theme-color-3: #3d66e1 !important;
  --font-size-14: 14px;
  --font-weight-400: 400;
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-border-width: 1px;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-emphasis-color: #000;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-table-accent-bg: transparent;
}

.container {
  /* max-width: 1680px; */
  max-width: 1320px;
  margin: 0 auto;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.common_heading_h2 {
  font-size: var(--common_heading_h2-font-size);
  line-height: var(--common_heading_h2-line-height);
  letter-spacing: var(--common_heading_h2-letter-spacing);
  padding-bottom: 30px;
  font-size: 32px;
  line-height: 1.2;
  color: #043873;
}

.common_padding {
  padding: 40px 0;
}

.section_design_bg {
  position: relative;
  overflow: hidden;
}

.section_design_bg::before,
.section_design_bg::after {
  content: '';
  position: absolute;
  background: url(./assets/images/flower_bg.webp) no-repeat;
  width: 278px;
  height: 295px;
  background-size: contain;
  top: -50px;
  z-index: -1;
}

.section_design_bg::before {
  left: -6%;
}

.section_design_bg::after {
  right: -8%;
}

@media(max-width: 768px){
  .section_design_bg::before,  .section_design_bg::after  {
    width: 188px;
    height: 200px;
  }
}


@media(max-width: 576px){
  .section_design_bg::before,  .section_design_bg::after  {
    width: 111.8px;
    height: 120px;
    top: 0;
  }
  .section_design_bg::after {
    right: -10%;
  }
}

@media (max-width: 1200px) {
  .common_padding {
    padding: 35px 0;
  }

  /* .common_heading_h2 {
    font-size: 50px;
    line-height: 60px;
    padding-bottom: 40px;
  } */
}

@media (max-width: 1024px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .common_padding {
    padding: 20px 0;
  }
  /* .common_heading_h2 {
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 30px;
  } */
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
  .common_padding {
    padding: 10px 0;
  }
  .common_heading_h2 {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
}

.ant-select-selection-item{
  font-size: 12px;
}
.ant-select-item-option-content{
  font-size: 12px !important;
}


.min-height{
  min-height: 264px;
}

.card-header .text_color p span{
  color: #6B7280 !important;
  font-size: 14px !important;
  word-wrap: break-word;
  word-break: break-word;
  font-family: 'poppins', sans-serif !important;
}

.custom-min-height {
  min-height: 40.1vh;
}