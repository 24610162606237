.getInTouchWriteContactUs {
  padding-top: 50px;
  padding-bottom: 50px;
}

.contact_us_bg {
  background: url("../images/contactUs/contact_us_bg.webp") no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.contactUsLink {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #fff;
  padding-left: 19px;
  max-width: 290px;
}

.contact_link {
  padding-bottom: 30px;
  border-radius: 10px;
}

.contact_link> a {
  padding: 15px;
  border-radius: 10px;
}

.contact_link >a>svg,
.contact_link >a>p,
.contact_link,
.contact_link>a> svg path,
.contact_link> a {
  transition: 0.4s ease;
}

.youtube_link {
  padding-right: 18.74px;
}

.contact_link:hover> a {
  background: #fff;
}

.contact_link:hover>a> svg,
.contact_link:hover>a> p,
.contact_link:hover>a> svg path {
  color: #043873;
  fill: #043873;
}

.youtube_link> svg path {
  border: 1px solid #fff;
}

.youtube_link> svg path,
.youtube_link> svg {
  transition: 0.4s ease;
}

.contact_links{
    padding: 15px;
}

.contact_link svg{
    min-width: 21px;
}
  
@media(max-width: 992px){
    .contact_links iframe{
        width: 100%;
    }

    .contact_us_bg > div{
        padding: 20px;
     }

    .contact_link {
        padding-bottom: 10px;
    }
}

@media(max-width: 768px){
    .contact_form > div > .contact_us_bg{
        order: 2;
    }
    .getInTouchWriteContactUs{
        padding-left: 2%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .contact_form{
        padding-top: 0;
        padding-bottom: 0;
    }


    
}

@media(max-width: 576px){
    .contact_us_bg > div {
        padding: 0;
    }

}

