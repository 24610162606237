/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */

.footer_pad {
  padding-bottom: 0;
}

.footer_logo{
  max-width: 200px;
}

.footer_part {
  /* background: url('../images/footer/footer_bg.webp') no-repeat; */
  background-size: cover;
  /* padding: 17px 0 84px 0; */
  padding: 50px 0 50px 0;
  background-color: #F4F7FA;
}

footer .container {
  max-width: 1380px;
  margin: 0 auto;
}

.footer_text {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  padding-right: 15px;
  margin-left: auto;
  max-width: 1094px;
  padding-bottom: 46px;
}

.footer_logo {
  /* position: absolute; */
  /* left: -180px; */
  /* top: 0; */
  max-width: 174px;
  object-fit: contain;
}

.footer_parts {
  padding: 12px 16px;
}

.footer_part_title {
  padding-top: 13px;
  padding-bottom: 17px;
}

.footer_part_title,
.footer_part_text,
.footer_part_text span {
  font-size: 15px;
  line-height: 24px;
  /* color: #fff; */
  color: #464646
}

.footer_pad_bottom {
  padding-bottom: 32px;
}

.social_icon_item {
  padding-right: 16px;
  padding-bottom: 16px;
}

.footer_part_text .playStore_footer {
  max-width: 169px;
  margin-bottom: 16px;
}


.footer_para{
font-size: 16px;
font-weight: 400;
line-height: 24px;
max-width: 400px;
color: #464646;
}

@media (max-width: 1720px) {
  .footer_logo {
    left: 0;
  }
}

@media (max-width: 1420px) {
  /* .footer_logo {
    position: relative;
    left: 0;
    top: 20px;
    margin-right: 20px;
  } */
  .footer_discription {
    align-items: baseline;
  }
}

@media (max-width: 1200px) {
  .footer_text {
    padding-bottom: 30px;
  }
  .footer_part{
    padding-bottom: 60px;
  }
}

@media (max-width: 1024px) {
  .footer_text {
    padding-bottom: 20px;
  }

  .footer_part_title,
  .footer_part_text,
  .footer_part_text span {
    word-break: break-word;
  }
  .footer_pad_bottom {
    padding-bottom: 20px;
  }

  .footer_part{
    padding-bottom: 40px;
  }
}

@media (max-width: 992px) {
  .footer_parts {
    /* width: 48%; */
    padding-bottom: 30px;
  }
  .footer_parts {
    padding-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
  }

  /* .footer_parts:nth-child(even) {
    padding-left: 20px;
  } */

  .footer_part_title {
    padding-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .footer_discription {
    flex-direction: column;
  }
  /* .footer_logo {
    position: static;
    margin-bottom: 15px;
    margin-top: 15px;
  } */
  .footer_part{
    padding-bottom: 0;
  }

  .footer_parts{
    padding-bottom: 10px;
  }
}

@media (max-width: 500px) {
  .footer_parts {
    width: 100%;
    max-width: 414px;
    margin: 0 auto;
  }
  /* .footer_parts:nth-child(even) {
    padding-left: 0;
  } */
  .footer_part_title{
    padding-top: 0;
  }
}

@media(max-width: 400px){
  .footer_part_text .playStore_footer {
    max-width: 110px;
    object-fit: contain;
    margin-bottom: 5px;
}
}

.footer_line {
  background: #0b1a46;
  padding: 20px 0;
}

.footer_bottom_text {
  /* font-family: 'Montserrat'; */
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: -0.5px;
  text-align: center;
  color: #fff;
  padding: 0 15px;
}


.footer_parts h3 {
  color: black;
  font-weight: 600;
}