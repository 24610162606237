.banner {
  /* background: url("../images/homepage/bannerBg.webp"); */
  /* padding: 154px 0 100px 0; */
  padding: 60px 0 0px 0;
  margin-top: -99px;
}

.banner h2 {
  /* font-size: var(--h2-font-size); */
  font-size: 50px;
  line-height: var(--h2-line-height);
  letter-spacing: var(--h2-letter-spacing);
  color: #043873;
  max-width: 500px;
}

.header_logo {
  max-height: 50px;
}

.profile_image {
  max-width: 40px;
  min-width: 40px;
  border-radius: 50%;
}


.sitemap_loader{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile_section {
  background: #f7f7f7;
  border-radius: 5px;
}

.bg_header {
  background: #043873;
  /* background-color: #F4F7FA; */
}

.profile_section svg,
.profile_section p {
  transition: 0.4s ease;
}
.profile_section:hover {
  background: #f7f7f7 !important;
}
/* .profile_section:hover svg, .profile_section:hover p{
  color: #fff;
  fill: #fff;
} */

p.banner_text {
  font-size: var(--banner_text-font-size);
  line-height: var(--banner_text-line-height);
  letter-spacing: var(--banner_text-letter-spacing);
  color: #043873;
  padding: 24px 5px 60px 0;
}

.hamburger {
  display: none;
}

.relative:has(>.hamburger){
  cursor: pointer;
}

.loader-cotainer {
  min-height: 33.2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_first_last_name {
  max-width: 150px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media (max-width: 1200px) {
  .banner h2 {
    font-size: 50px;
    line-height: 60px;
  }
  p.banner_text {
    font-size: 16px;
    line-height: 22px;
    padding: 20px 0 30px 0;
  }
  .banner {
    padding: 154px 0 100px 0;
  }

  .banner > div > div > div > img {
    padding: 0 15px;
  }
}

@media (max-width: 1024px) {
  .banner h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .banner {
    padding: 124px 0 80px 0;
  }
}

@media (max-width: 768px) {
  p.banner_text {
    padding: 15px 0 20px 0;
  }
  .banner h2 {
    font-size: 25px;
    line-height: 32px;
  }
}

@media (max-width: 576px) {
  .banner > div > div {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }

  .banner > div > div > div {
    width: 100%;
  }

  .banner > div > div > div:first-child {
    padding-bottom: 15px;
  }
  /* .banner {
    padding: 100px 0 40px 0;
  } */
}

.ipo_initial_public_offering {
  background-color: #f4f7fa;
}

.ipo_box {
  /* box-shadow: 0px 2px 10px 0px #0000001c; */
  /* border: 0.5px solid #212529; */
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: max-content;
  margin: 0 auto;
}

.ipo-pill {
  border: 1px solid #212529;
  padding: 10px 15px;
  border-radius: 70px;
  margin-right: 20px;
}

.ipo-pill > div:first-child {
  width: 45px;
  height: 45px;
  padding: 5px;
  border: 1px solid #212529;
}

.ipo-text h3 {
  font-size: var(--ipo-head-font-size);
  line-height: var(--ipo-head-line-height);
  letter-spacing: var(--ipo-head-letter-spacing);
}

.svg_ipo_icons path {
  fill: #212529;
}

.ipo-pill:hover .svg_ipo_icons path {
  fill: #043873;
}

.ipo-pill:hover {
  background: #043873;
}

.ipo-text,
.svg_ipo_icons path,
.ipo-pill {
  transition: 0.4s ease;
}

.ipo-pill:hover .ipo-text {
  color: #fff;
}

.home-filter-dropdown {
  list-style: none;
  border-radius: 4px !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home-filter-dropdown {
  position: relative;
  box-shadow: 0px 1px 1px 0px #0000001a;
  /* border: 1px solid #212529; */
}

.home-filter-dropdown .dropdown-menu {
  min-width: 200px;
  /* padding: 8px 0; */
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 5px;
  top: 100%;
  display: none;
  border: 1px solid #dbdbdb;
  overflow: hidden;
}

.home-filter-dropdown .dropdown-menu.active {
  display: block;
  background: #fff;
  z-index: 11;
}

.home-filter-dropdown .dropdown-item {
  padding: 12px 16px;
  color: var(--theme-color-1);
  transition: all 0.3s ease;
  font-size: 14px;
  transition: 0.4s ease;
}

.home-filter-dropdown .dropdown-item:hover {
  background-color: #043873;
  color: white;
}

.home-filter-dropdown .dropdown-item.active {
  background-color: #043873;
  color: #fff;
  font-weight: 500;
}

.home-filter-dropdown .dropdown-divider {
  margin: 8px 0;
  border-color: #eee;
}

.home-filter-dropdown .dropdown-menu {
  min-width: 100%;
}

.home-filter-dropdown .dropdown-item {
  font-size: 16px;
}

.filter-inner-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 16px;
  background: white;
  border-radius: 8px;
  border: 1px solid #eee;
  transition: all 0.3s ease;
  min-width: 170px;
}

.filter-inner-sec {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
  font-family: var(--body-font-family);
  font-size: 16px;
  font-weight: var(--font-weight-400);
}

.nav-item .nav-link {
  color: var(--theme-color-1);
  transition: color 0.3s ease;
}

.dropdown-title {
  color: var(--theme-color-1);
  font-weight: 500;
}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.filterTags {
  border-radius: 4px;
  padding: 5px 9.6px 6px 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 4px;
  max-width: max-content;
}

.mainBoardTag:not(:last-child) {
  background: #4f9cf9;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.preApply {
  background: #6055cd26;
}

.live {
  background: #ffb4b426;
  color: #ff0000;
}

.ipo_news {
  position: relative;
}

.ipo_news::before {
  content: "";
  background: url(../images/flower_bg.webp) no-repeat;
  position: absolute;
  right: -35px;
  top: -18px;
  width: 278.27px;
  height: 295px;
  z-index: -1;
}

.preApplyDot > .dot {
  background: #212529;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  top: -0.5px;
}

.ipo_list {
  gap: 2%;
}

.ipo_list .ipo_list_sec {
  width: 32%;
  margin-bottom: 2%;
}

.ipo_list > li > div {
  box-shadow: 0px 4px 34px 0px #0000001c;
}

.ipo_list > li > div img {
  max-height: 150px;
}

.ipoDetailText {
  font-size: 24px;
  font-weight: 400;
  line-height: 21px;
  color: #212529;
  padding-bottom: 17px;
}

.viewPreAppyBtn {
  padding: 19px 46px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  transition: 0.4s ease;
}

.viewBtn {
  color: #212529;
  background: #f1f4ff;
}

.viewBtn:hover {
  background: #212529;
  color: #f1f4ff;
}

.preApplyBtn {
  color: #f1f4ff;
  background: #4f9cf9;
}

.preApplyBtn:hover {
  background: #f1f4ff;
  color: #4f9cf9;
}

.view_more {
  color: #4f9cf9;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.6px;
  letter-spacing: -0.10000000149011612px;
  text-decoration: underline;
  position: relative;
  top: 13px;
}

.innerFilterType {
  max-width: 200px;
}

.home-primary-button_1:disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 1420px) {
  .viewPreAppyBtn {
    padding: 13px 30px;
  }
}

@media (max-width: 1420px) {
  .viewPreAppyBtn {
    padding: 10px 20px;
  }
  .ipoListPad {
    padding: 40px 0;
  }

  .view_more {
    top: 10px;
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .ipo-text h3 {
    font-size: 18px;
    line-height: 25px;
  }

  .ipoDetailText {
    font-size: 22px;
    line-height: 21px;
  }

  .ipo_list_sec > div {
    padding: 30px;
  }

  .smalltags {
    padding-bottom: 30px;
  }

  .ipo_news::before {
    right: -75px;
    top: -108px;
    width: 250px;
    height: 295px;
  }
}

@media (max-width: 1024px) {
  .viewPreApplySection {
    flex-wrap: wrap;
  }
  .ipo_news::before {
    top: -28px;
    width: 150px;
    right: -70px;
    height: 120px;
    background-size: contain;
  }

  .ipo_list > .ipo_list_sec {
    width: 49%;
  }

  .viewPreAppyBtn {
    margin-bottom: 15px;
  }

  .ipoListPad {
    padding: 30px 0;
  }

  .ipo-text h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .ipo-pill > div:first-child {
    width: 35px;
    height: 35px;
  }

  .ipo-pill {
    margin-right: 10px;
  }

  .ipoDetailText {
    font-size: 20px;
  }

  .ipoDetail {
    padding-bottom: 10px;
  }

  .ipo_list > li > div img {
    margin-bottom: 30px;
  }

  .ipoDetailText {
    padding-bottom: 15px;
  }
  .ipo_list_sec > div {
    padding: 20px;
  }

  .smalltags {
    padding-bottom: 20px;
  }

  .view_more {
    font-size: 18px;
    line-height: 23px;
    top: -20px;
  }
}

@media (max-width: 768px) {
  .ipoListPad {
    padding: 20px 0;
  }
  .ipo-text h3 {
    font-size: 15px;
  }

  .ipo-pill > div:first-child {
    width: 30px;
    height: 30px;
  }

  .ipo-pill img {
    padding: 1px;
  }
  .ipoDetailText {
    font-size: 18px;
  }
  .ipo_list > li > div img {
    margin-bottom: 20px;
  }
  .ipoDetailText {
    padding-bottom: 10px;
  }
  .ipo_list_sec > div {
    padding: 20px 20px 10px 20px;
  }
}

@media (max-width: 576px) {
  .ipo_list > .ipo_list_sec {
    width: 100%;
    margin-bottom: 20px;
  }
  .view_more {
    font-size: 16px;
    line-height: 23px;
    top: 0;
  }

  .view_more_2 {
    top: -15px;
  }
}

.news_list_section {
  gap: 2%;
}

.news_list_item {
  width: 23%;
  margin-bottom: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.news_list_block {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}

.news_list_item .news_list_block {
  box-shadow: unset;
  border-radius: unset;
}

.news_list_block img {
  border-radius: 20px;
  margin-bottom: 5px;
}

.news_list_block .news_image_part {
  min-height: 206px;
  display: flex;
  align-items: center;
}

.news_list_block .news_image_part img {
  border-radius: 5px;
  max-height: 148px;
  width: 100%;
  object-fit: cover;
}

.news_list_block h3 {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  /* padding-bottom: 34px; */
  margin-bottom: 5px;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news_list_block .createdAt {
  margin-bottom: 11px;
  font-size: 12px;
}

.news_list_block p {
  font-size: 16px;
  line-height: 28.8px;
  color: #464646;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news_list_block a.readmore {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
}

.down-arrow {
  margin-bottom: 0 !important;
  margin-left: 8px;
  position: relative;
  top: -1px;
}

@media (max-width: 1200px) {
  .news_list_block img {
    margin-bottom: 15px;
  }
  .news_list_block h3 {
    /* padding-bottom: 15px; */
    /* margin-bottom: 15px; */
  }

  .news_list_block .createdAt {
    margin-bottom: 15px;
  }
  .news_list_para {
    padding-bottom: 7px;
  }
}
@media (max-width: 1040px) {
}
@media (max-width: 1024px) {
  .news_list_item {
    width: 48%;
  }
  .news_list_section {
    gap: 4%;
  }
}

@media (max-width: 576px) {
  .news_list_item {
    width: 100%;
  }
}

.lets_download_section {
  background: #043873;
  padding-top: 100px;
  padding-bottom: 100px;
}

.lets_download_section h2 {
  font-size: 42px;
  font-weight: 700;
  line-height: 55.03px;
  letter-spacing: -0.02em;
  color: #ffffff;
  max-width: 760px;
  padding-bottom: 56px;
}

.lets_download_section_para {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
  max-width: 550px;
  padding-bottom: 56px;
}

.playstore {
  margin-right: 13px;
}

.storeImg,
.playstore {
  max-width: 200px;
  object-fit: contain;
}

.storeImg_2 {
  max-width: 160px;
}

.ipo-app {
  top: -29%;
  right: 3%;
  width: 77%;
}

@media (max-width: 1520px) {
  .ipo-app {
    top: -25%;
  }
  .lets_download_part_1 {
    width: 60%;
  }
  .lets_download_part_2 {
    width: 40%;
  }
  .lets_download_section {
    padding: 80px 0;
  }
}

@media (max-width: 1320px) {
  .lets_download_section h2 {
    font-size: 36px;
    line-height: 45px;
    max-width: 450px;
    padding-bottom: 35px;
  }

  .lets_download_section_para {
    max-width: 450px;
    padding-bottom: 35px;
  }
  .ipo-app {
    right: 6%;
    top: -20%;
    width: 96%;
  }

  .ipo-app img {
    width: 90%;
  }

  .lets_download_section {
    padding: 50px 0;
  }
}

@media (max-width: 1200px) {
  .ipo-app img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .lets_download_part_1,
  .lets_download_part_2 {
    width: 100%;
  }

  .lets_download_part_2 {
    padding-left: 5%;
  }

  .ipo-app {
    right: 2%;
    top: -21%;
    width: 90%;
  }

  .lets_download_part_2 {
    padding-left: 20px;
  }

  .ipo-app img {
    width: 94%;
  }
  .ipo-app {
    right: -2%;
  }

  .appPlayStore {
    flex-wrap: wrap;
  }

  /* .storeImg {
    max-width: 150px;
    object-fit: contain;
    margin-bottom: 20px;
  } */

  .lets_download_section h2 {
    font-size: 30px;
    line-height: 40px;
    max-width: 350px;
    padding-bottom: 20px;
  }
  .lets_download_section_para {
    max-width: 350px;
    font-size: 15px;
    padding-bottom: 20px;
  }
  .lets_download_section {
    padding: 30px 0;
  }
  .ipo-app {
    right: 2%;
    top: -14%;
    width: 70%;
  }
  .ipo-app img {
    width: 100%;
  }
}

@media (max-width: 892px) {
  .ipo-app {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .lets_download_section h2 {
    font-size: 23px;
    line-height: 30px;
    padding-bottom: 15px;
  }
  .lets_download_section_para {
    max-width: 250px;
    font-size: 11px;
    padding-bottom: 20px;
    line-height: 20px;
  }
  /* .storeImg {
    max-width: 110px;
    object-fit: contain;
    margin-bottom: 5px;
  } */

  .playstore {
    margin-right: 12px;
  }

  .ipo-app {
    width: 100%;
    position: static;
  }

  .ipo-app img {
    width: 75%;
    position: absolute;
    top: -20%;
    right: 10px;
  }
}

@media (max-width: 692px) {
  .ipo-app img {
    width: 93%;
  }
}

@media (max-width: 600px) {
  .ipo-app img {
    width: 100%;
    top: -15%;
  }
}

/* .ipo-app img {
  width: 100%;
} */

@media (max-width: 576px) {
  .lets_download_part_1,
  .lets_download_part_2 {
    width: 100%;
  }
  .lets_download_all_parts {
    flex-direction: column-reverse;
  }
  .lets_download_section h2 {
    max-width: 100%;
  }
  .lets_download_section_para {
    max-width: 100%;
  }
  .lets_download_section {
    padding-bottom: 0;
  }
  .lets_download_part_1 {
    position: relative;
    top: -20px;
  }
  .ipo-app img {
    position: relative;
    top: -40px;
  }
}

@media(max-width: 400px){
    .storeImg {
    max-width: 110px;
    object-fit: contain;
    margin-bottom: 5px;
  }
  .auth_submit_login_check_ipos {
    padding: 8px 15px;
    max-height: max-content;
    font-size: 14px;
    margin-bottom: 15px;
}
}

/* // */

.top_brokers_item {
  padding-bottom: 16px;
}

.typ_brokers {
  position: relative;
}

.typ_brokers::before {
  content: "";
  background: url(../images/flower_bg.webp) no-repeat;
  position: absolute;
  left: -35px;
  top: -58px;
  width: 278.27px;
  height: 295px;
  z-index: -1;
}

.top_brokers_item > div {
  box-shadow: 0px 1px 1px 0px #0000001a;
  border: 0.5px solid #212529;
  padding: 17px 29px 25px;
  border-radius: 6px;
}

.brokerImg {
  max-width: 80px;
  min-width: 80px;
  margin: 0 auto;
  display: block;
}

.broker_name {
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
  color: #212529;
  padding-top: 8px;
  text-align: center;
  max-width: 113px;
  margin: 0 auto;
}

.Equity_list_item {
  padding-bottom: 15px;
}

.Equity_list_item > div {
  background: #eeeeee;
  padding: 6px 9.66px 10px 10px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #c4834a;
}

.broker_right_img {
  max-width: 15px;
  max-height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
}

.broker_write {
  padding-left: 66px;
}

.Equity_list_item:not(:last-child) {
  padding-right: 8px;
}

.Equity_list_item:last-child {
  padding-right: 32px;
}

.broker_btn_open_account {
  padding: 7px 11.36px 7px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  transition: 0.4s ease;
  margin-bottom: 15px;
}

.broker_view_details {
  border: 1px solid #f1f4ff;
  background: #f1f4ff;
  color: #212529;
  margin-right: 16px;
}

.broker_open_account {
  border: 1px solid #4f9cf9;
  background: #4f9cf9;
  color: #fff;
}

.broker_view_details:hover {
  color: #f1f4ff;
  background: #212529;
}

.broker_open_account:hover {
  color: #4f9cf9;
  background: #fff;
}

.broker_qualities {
  padding-bottom: 9px;
}

.account_opening {
  font-size: 14px;
  line-height: 21px;
  color: #212529;
  padding-bottom: 5px;
}
.account_rupees {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #212529;
}

.broker_weigtage_item {
  min-width: 170px;
  padding-right: 32px;
  padding-bottom: 15px;
}

.broker_weigtage_item:last-child {
  padding-right: 0;
}

.top_broker_img_section {
  min-width: 118px;
}
.mobile_broker_btn {
  display: none;
}
.top_brokers_item_part {
  background: #fff;
}

@media (max-width: 1200px) {
  .typ_brokers::before {
    right: -75px;
    top: -108px;
    width: 250px;
    height: 295px;
  }
}

@media (max-width: 1096px) {
  .mobile_broker_btn {
    display: block;
  }
  .desktop_broker_btn {
    display: none;
  }
  .broker_name {
    font-size: 22px;
    line-height: 30px;
  }
}

@media (max-width: 1024px) {
  .typ_brokers::before {
    top: -28px;
    width: 150px;
    right: -70px;
    height: 120px;
    background-size: contain;
  }
}

@media (max-width: 768px) {
  .broker_name {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 620px) {
  .top_brokers_item > div {
    flex-direction: column;
    padding-bottom: 10px;
  }
  .top_broker_img_section {
    padding-bottom: 15px;
  }
  .broker_write {
    padding-left: 0;
  }
  .top_brokers_item > div {
    padding: 10px 15px 5px 15px;
  }
  .broker_name {
    font-size: 18px;
    line-height: 23px;
  }
}

.about_us_home_write {
  padding-left: 5%;
}

.about_us_home_write h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  letter-spacing: -0.02em;
  color: #212529;
  margin-bottom: 56px;
}

.about_us_home_write h3::before {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #212529;
}

.about_us_para {
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  color: #212529;
  padding-bottom: 36px;
}

@media (max-width: 1200px) {
  .about_us_para {
    font-size: 16px;
    line-height: 25px;
    padding-bottom: 25px;
  }

  .about_us_home_write h3 {
    margin-bottom: 35px;
  }
}

@media (max-width: 1200px) {
  .about_us_para {
    font-size: 15px;
    line-height: 23px;
    padding-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .about_us_para {
    font-size: 14px;
    line-height: 23px;
    padding-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .about_us_home > div > div {
    flex-direction: column;
  }
  .about_us_home > div > div > div {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .about_us_img_home {
    padding-bottom: 25px;
  }

  .about_us_img_home img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}

.accordian_item {
  border: 0.5px solid #212529;
  box-shadow: 0px 2px 10px 0px #0000001c;
  border-radius: 4px;
  margin-bottom: 28px;
  padding-left: 15px;
  padding-right: 15px;
}

.icon {
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  position: relative;
  background-color: #043873;
  display: inline-block;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.icon::before,
.icon::after {
  content: "";
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus::before,
.minus::before {
  width: 60%;
  height: 4px;
}

.plus::after {
  width: 4px;
  height: 60%;
}

.minus::after {
  display: none;
}

.accrodian_title {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.02em;
  color: #212529;
  padding-right: 15px;
}

@media (max-width: 1200px) {
  .accrodian_title {
    font-size: 20px;
    line-height: 32px;
  }

  .accordian_item > button {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }

  .accordian_item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 1024px) {
  .icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
  .accordian_item > button {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .accrodian_title {
    font-size: 19px;
    line-height: 32px;
  }

  .accordian_item {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .accrodian_title {
    font-size: 16px;
    line-height: 25px;
  }

  .icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}

.getInTouchForm {
  box-shadow: 0px 4px 34px 0px #0000001c;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
}

.getInTouchForm h3 {
  font-size: 28px;
  font-weight: 600;
  line-height: 42.93px;
  padding-bottom: 0;
  color: #000;
}

.getInTouchWrite {
  padding: 0 0 0 128px;
}

.getInTouchBottomLine {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
}

.getInTouchForm_section {
  padding-top: 38px;
  max-width: 456.95px;
}

.input_field,
.input_field::placeholder {
  max-width: 256.18px;
  min-width: 256.18px;
  border-radius: 5px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14.52px;
  min-height: 41.7px;
  padding: 12px 10px;
}

.input_field_textarea {
  resize: none;
  min-width: 100%;
}

textarea::placeholder {
  padding: 0;
}

.input_field_section {
  padding-bottom: 33px;
}

.get_in_touch_img_sec {
  background: #043873;
  border-radius: 10px;
}

.input_submit_button {
  border-radius: 10px;
  transition: 0.4s ease;
  background: #212529;
  border: 1px solid #212529;
  color: #fff;
  font-size: 12px;
  line-height: 14.52px;
  padding: 14px 38px;
}

.input_submit_button:hover {
  color: #212529;
  background: #fff;
}

.error-text {
  bottom: 12px;
  left: 0;
}

@media (max-width: 1620px) {
  .getInTouchForm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 1420px) {
  .getInTouchForm h3 {
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 5px;
  }
  .getInTouchForm_section {
    padding-top: 40px;
  }
  .input_field_section {
    padding-bottom: 30px;
  }
  .error-text {
    bottom: 9px;
    font-size: 13px;
  }
  .getInTouchWrite {
    padding-left: 5%;
    padding-right: 2%;
  }
}

@media (max-width: 1024px) {
  .getInTouchForm_section {
    padding-top: 20px;
  }
  .error-text {
    font-size: 11px;
  }
  .getInTouchForm h3 {
    font-size: 35px;
    line-height: 43px;
  }
  .getInTouchForm h3 {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 768px) {
  .getInTouchForm > div {
    flex-direction: column;
  }
  .get_in_touch_img_sec {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .getInTouchForm > div > div {
    width: 100%;
  }

  .getInTouchForm_section,
  .input_field {
    max-width: 100%;
    width: 100%;
    min-width: unset;
  }
  .getInTouchForm h3,
  .getInTouchBottomLine {
    text-align: center;
  }
  .getInTouchForm h3 {
    font-size: 25px;
    line-height: 33px;
    padding-bottom: 0;
  }
  .input_submit_button {
    padding: 10px 25px;
  }
  .getInTouchForm {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.Newsletter {
  padding-bottom: 5px;
}

.newsLetterError {
  max-width: 1096px;
  margin: 0 auto;
  bottom: -26px;
  left: 0;
}

.subscribe_text {
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  color: #262626;
  padding-bottom: 34px;
}

.subscription-box {
  display: flex;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1086px;
  margin: 0 auto;
  border: 1px solid #212529;
  overflow: hidden;
}

.email-input {
  flex: 1 1;
  padding: 14px 15px 14px 36px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #333;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.email-input::placeholder {
  color: #333;
}

.subscribe-button {
  padding: 12px 102.52px;
  border: none;
  background-color: #043873;
  border-left: 1px solid #043873;
  overflow: hidden;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
}

.subscribe-button:hover {
  color: #043873;
  background-color: #ffffff;
  border-left: 1px solid #000;
}

.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media (max-width: 1024px) {
  .subscribe_text {
    padding-bottom: 30px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .subscribe-button {
    padding: 12px 60px;
  }

  .subscribe_text {
    font-size: 14px;
    padding-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .subscribe-button {
    padding: 12px 30px;
  }
  .email-input {
    flex: unset;
    padding: 14px 15px 14px 16px;
    max-width: 160px;
  }
  .subscription-box {
    justify-content: space-between;
  }
}

/* extra brokerslist css */

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin: 20px 0;
}

.active-badge {
  background-color: #f3f2fe !important;
  color: #6055cd !important;
}

.service-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 6px 8px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  min-height: 32px;
  line-height: 1.2;
}

.feature-badge {
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 12px;
  background-color: #e5e6f2;
  color: #3d66e1;
  white-space: nowrap;
}

.broker-features {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
}

.broker-card {
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  box-shadow: 0 4px 20px #00000014;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.broker-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px #0000001f;
}

.broker-card-header {
  margin-bottom: 20px;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px #00000026;
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.broker-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.broker-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 12px;
  padding: 8px;
  background: #f8f9fa;
}

.broker-title h3 {
  margin: 0;
  font-size: 20px;
  color: #0b1a46;
  font-weight: 600;
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
}

.stars {
  color: #ffc107;
  font-size: 14px;
}

.rating-value {
  color: #6c757d;
  font-size: 14px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin: 20px 0;
}

.service-badge span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.active-badge {
  background-color: #f3f2fe !important;
  color: #6055cd !important;
}

.service-badge i {
  font-size: 10px;
}

.broker-details {
  margin-bottom: 20px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.detail-label {
  font-size: 13px;
  color: #6c757d;
  font-weight: 500;
}

.detail-value {
  font-size: 14px;
  color: #0b1a46;
  font-weight: 500;
}

.broker-actions {
  display: flex;
  gap: 10px;
  margin-top: auto;
}

.broker-actions button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 50%;
  font-size: 14px;
  min-width: max-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home-primary-button_1 {
  background-color: #f0f0f0 !important;
  color: #000;
}

.home-primary-button {
  /* background-color: #0b1a46 !important; */
  background-color: #043873 !important;
  color: #fff;
}

@media (max-width: 1200px) {
  .services-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) {
  .service-badge {
    font-size: 11px;
    padding: 4px 6px;
  }
}

@media (max-width: 768px) {
  .broker-section .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/* //ipo list */
.ipoCardDetailsHome {
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  flex-wrap: wrap;
  /* padding-top: 30px; */
  justify-content: start;
  gap: 2%;
}

.ipo-card {
  border-radius: 16px;
  box-shadow: 0 8px 30px #0000001f;
  background: #fff;
  transition: all 0.3s ease;
  padding: 0;
  width: 32%;
  margin-bottom: 20px;
  /* flex: 0 0 calc(33.333% - 20px); */
  /* margin: 10px; */
}

.ipo-card-inner {
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px #00000014;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ipo-card-header {
  padding: 20px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  align-items: flex-start;
  flex-direction: column;
}

.card-header-top {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.company-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 12px;
  background: #f1f3f5;
  padding: 5px;
}

.company-name {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.company-name_ios {
  padding-right: 0;
}

.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  /* position: relative; */
  /* right: -10px; */
  /* flex-direction: column; */
}

.right_0{
  right: 0;
}

.badge-container_ios {
  right: 20px;
}

.badge {
  padding: 4px 8px;
  /* border-radius: 12px; */
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 1;
  max-width: max-content;
  min-width: max-content;
}
.type-badge {
  background-color: #6c5ce7;
}

.status-badge {
  background-color: #fff4f4;
  color: red !important;
}

.live {
  background-color: red;
}

.liveUpcomingListed {
  width: 5px;
  height: 5px;
  min-width: 5px;
  min-height: 5px;
  border-radius: 50%;
}

.ipo-card-content {
  padding: 20px;
  flex-grow: 1;
}

.ipo-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.label {
  font-size: 14px;
  color: #6c757d;
}

.value {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.ipo-card-actions {
  padding: 20px;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.applyIpo {
  flex: 1;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #f0f0f0;
}

.applyIpo_View_details {
  background: #0b1a46;
  font-size: 14px;
  padding: 10px;
  height: 55px !important;
}

.value {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.highlight {
  color: #27ae60;
}

.home-primary-button:hover {
  color: #fff;
}

.disabled_ipo_btn{
  opacity: 0.5;
  cursor: unset;
  pointer-events: none;
}

.ipo-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px #0000001f;
}

/* new tabs css */
.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
}
.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 10px 20px #0729131a, 0 0 20px #0729131a;
  padding: 10px;
  border-radius: 100px;
}

input[type="radio"] {
  display: none;
}

.tabs > * {
  z-index: 2;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 200px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #0b1a46;
}

input[type="radio"]:checked + label {
  color: #3d66e1;
  font-weight: 600;
  transform: scale(1.05);
}

input[type="radio"]:checked + label {
  animation: tabSelect 0.3s ease-out;
}

.selector {
  position: absolute;
  height: 54px;
  width: 200px;
  background-color: #e5e6f2;
  z-index: 1;
  border-radius: 100px;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  left: 10px;
}

input[type="radio"]:checked + label {
  color: #3d66e1;
  font-weight: 600;
  transform: scale(1.05);
}

.tab1,
.tab2,
.tab3 {
  color: #043873;
  font-weight: 600;
  transform: scale(1.05);
}

.tab1 ~ .selector {
  transform: translate(0);
}

.tab2 ~ .selector {
  transform: translate(200px);
}

.tab3 ~ .selector {
  transform: translate(400px);
}

@media (max-width: 1200px) {
  .ipo-card {
    max-width: 48%;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .company-name {
    padding-right: 5px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .company-name {
    padding-right: 30px;
  }
}

/* filter css */

.home-filter-dropdown {
  position: relative;
}
.nav-item {
  position: relative;
}
.home-filter-dropdown {
  list-style: none;
  padding: 10px !important;
  border-radius: 4px !important;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: unset;
}

.nav-item .nav-link {
  color: var(--theme-color-1);
  transition: color 0.3s ease;
}

.nav-link {
  color: var(--theme-color-1) !important;
  font-size: 16px;
  font-weight: var(--font-weight-500);
  display: flex;
  justify-content: center;
}

.filter-inner-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: var(--font-weight-400);
}

.filter-inner-sec {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #eee;
  transition: all 0.3s ease;
  min-width: 200px;
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  height: 2px;
  background-color: var(--theme-color-2);
  width: 0;
  transition: 0.4s ease;
}

.nav-item:hover:after {
  width: 100%;
  left: 0;
  transform: scaleX(1);
}

.down_arrow_filter {
  position: relative;
  top: 2px;
}

.dropdown-title {
  min-width: 100px;
  text-align: center;
}

.live-status-badge {
  background-color: rgba(255, 0, 0, 0.089);
  color: red;
}

.listed-status-badge {
  background: rgb(0 195 0 / 9%);
  color: green;
}

.liveUpcomingListed.upcoming {
  background: blue;
}

.upcoming-status-badge {
  background: rgb(0 33 149 / 8%);
  color: blue;
}

.liveUpcomingListed.listed {
  background: green;
}

.home-filter-dropdown .open_filter {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(10px, 58px);
  display: block;
  background: #fff;
  z-index: 11;
}

.home-filter-dropdown .dropdown-item:hover,
.home-filter-dropdown .dropdown-item.active {
  background-color: var(--theme-color-2);
  color: #043873;
  position: relative;
}

.home-filter-dropdown .dropdown-item.active::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: var(--theme-color-3);
}

.about-us-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .ipo-card {
    width: 49%;
  }
  .ipoCardDetailsHome {
    gap: 4%;
  }
}

@media (max-width: 992px) {
  .ipoCardDetailsHome {
    padding-top: 0;
  }

  .ipoListPad {
    padding-top: 20px;
  }

  .tabs {
    margin-bottom: 20px;
  }

  .ipo_box {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .ipoCardDetailsHome {
    display: unset;
  }
  .filters {
    flex-direction: column;
    padding: 10px;
  }
  .tabs {
    display: flex;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 10px 20px 0 rgba(7, 41, 19, 0.1),
      0 0 20px 0 rgba(7, 41, 19, 0.1);
    padding: 10px;
    border-radius: 100px;
    margin-bottom: 20px;
  }

  .tab {
    width: 120px;
    font-size: 1rem;
  }

  .selector {
    width: 120px;
  }

  .tab2 ~ .selector {
    transform: translate(120px);
  }

  .tab3 ~ .selector {
    transform: translate(240px);
  }

  .ipo-card {
    max-width: 100%;
    width: 100%;
  }
  .card-header-top .badge-container {
    flex-direction: unset;
  }
}

@media (max-width: 700px) {
  /* .ipo-card {
    max-width: 100%;
    width: 100%;
  } */
}

@media (max-width: 576px) {
  .tab {
    font-size: 0.8rem;
    height: 34px;
  }
  .tab,
  .selector {
    width: 90px;
    height: 34px;
  }
  .tab2 ~ .selector {
    transform: translate(90px);
  }

  .tab3 ~ .selector {
    transform: translate(182px);
  }

  .card-header-top {
    flex-direction: column;
  }
  .card-header-top .logo-container {
    order: 2;
    padding-top: 10px;
  }
  .card-header-top .badge-container {
    justify-content: flex-end;
    width: 100%;
    order: 1;
    right: 0;
  }
}

/* company detail css */

.details-page-card-grid {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 0;
  margin: 0;
}

.detail-page-card {
  border: none;
  box-shadow: 0px 1px 1px 0px #0000001a;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 0;
}

.border {
  /* border-top: 4px solid #0B1A46 !important; */
}

.detail-page-card ul li {
  list-style: none;
}

.detail-page-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.overflow-x {
  overflow-x: scroll;
}

.detail-card-inner {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 20px;
  border-radius: 15px;
}

.heading-group {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
  gap: 10px;
}

.appStoreLinks {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.language {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-header {
  background-color: transparent;
  padding-bottom: 10px;
}

.app-store-link {
  margin: 0;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 1200px) {
  .ipo-card {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .ipo-card {
    flex: 0 0 calc(100% - 20px);
  }

  .ipo-card-header {
    padding: 15px;
  }

  .company-logo {
    width: 50px;
    height: 50px;
  }

  #section2 {
    padding-top: 0 !important;
  }

  .ipo-card-content,
  .ipo-card-actions {
    padding: 15px;
  }
}

#section2 {
  padding: 60px 0 0 0;
}

.ipo-card {
  padding: 0;
  border-radius: 15px;
}

.custom-foot-menu {
  display: block;
}

.heding-details {
  font-family: var(--heading-font-family);
}

.detail-card-inner {
  font-size: var(18px);
  padding-bottom: 20px;
}

.detail-card-inner table tr,
td {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
}

.detail-card-inner p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  padding: 5px;
  word-wrap: break-word;
}

.detail-page-card ul li {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-400);
  padding: 5px;
  word-wrap: break-word;
}

.detail-page-card ul {
  padding: 10px;
}

.dropdown-menu {
  display: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-item.active {
  background-color: var(--theme-color-2);
  color: var(--theme-color-3);
}

.table_1 > tbody > tr:nth-child(odd) {
  background: #1f749421;
}

.table_1 {
  margin-bottom: 30px;
}

.table_1_heading {
  align-items: center;
}

.table_1_heading .head-img {
  min-width: 70px;
}

.table_2 thead tr {
  background: #1f749421;
}

.table_2 thead th {
  font-weight: 600;
}
.heding-details {
  font-size: 2rem;
  line-height: 32px;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .table_1 > tbody > tr > td {
    text-align: left;
  }

  .table_1 > tbody > tr > td {
    padding: 10px;
  }

  .heding-details {
    font-size: 16px !important;
    line-height: 25px !important;
  }
  .card-header:has(.heding-details) {
    padding-left: 0 !important;
  }
}

.detail-card-inner {
  box-shadow: 0 4px 20px #00000014;
  padding: 20px;
  border-radius: 15px;
}

.card-header {
  background-color: transparent;
  padding-bottom: 10px;
}

.checkIpoButton:hover {
  background: #fff;
  color: var(--theme-color-1);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-border-width) solid var(--bs-border-color-translucent);
}

.table > :not(caption) > * > * {
  padding: 0.5rem;
  color: var(
    --bs-table-color-state,
    var(--bs-table-color-type, var(--bs-emphasis-color))
  );
  background-color: var(--bs-body-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px
    var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

/* Add this CSS to style the pagination */
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
  box-shadow: 0 3px 8px #0000003d;
  max-height: max-content;
}

.pagination li a {
  /* background: #043873; */
    background: #fff;
  border: 1px solid #043873;
  border-radius: 4px;
  padding: 12px 15px;
  /* color: #fff; */
    color: #043873;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.94px;
  transition: 0.4s ease;
  cursor: pointer;
  /* box-shadow: 0px 4px 15px 0px #fff; */
  box-shadow: 0 3px 8px #0000003d;
  display: flex;
}

.pagination li a:hover {
  /* background: #fff;
  color: #043873; */
  background: #043873;
  color: #fff;
}

/* .pagination .active a {
  background: #fff;
  color: #043873;
} */

.pagination .active a {
  background: #043873;
  color: #fff;
}

.pagination {
  padding-top: 30px;
}

.pagination li a[aria-disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
  color: #767676;
}

.CurrentIPO {
  padding-bottom: 0;
}
