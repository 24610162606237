header {
  transition: 0.4s ease;
  background: #043873;
  position: relative;
    z-index: 11;
}
.navBar .navbar_list > li > a{
  position: relative;
}

.navBar .navbar_list > li:not(.login_register_link) > a::before, .navBar .navbar_list > li:not(.login_register_link) > a.active::before{
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  height: 1px;
  width: 0;
  transition: 0.4s ease;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
}
.navBar .navbar_list > li:hover > a::before, .navBar .navbar_list > li:not(.login_register_link) > a.active::before {
  left: 0;
  width: 100%;
  transform:translateX(0)
}

/* .navBar .navbar_list > li:hover > a, .navBar .navbar_list > li:not(.login_register_link) > a.active{
  color: yellow;
} */

.sticky_nav {
  position: sticky;
  top: 0;
  transition: 0.4s ease;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.navBar_pad {
  padding: 10px 0;
}

.navbar_list {
  padding: 12px 0;
}

.navbar_list > li {
  padding-right: 32px;
}

.navbar_list > li:not(:has(button)) {
  max-height: 29px;
}

.navbar_list > li > a,
.submenu > li > a {
  font-size: var(--navbar_list-font-size);
  line-height: var(--navbar_list-line-height);
  color: var(--navbar-color);
}

.navbar_button {
  background: var(--navbar-button-background);
  font-size: var(--navbar-button-font-size);
  line-height: var(--navbar-button-line-height);
  letter-spacing: var(--navbar-button-letter-spacing);
  padding: 10px 25px;
  border-radius: 8px;
  transition: 0.4s ease;
  border: 1px solid var(--navbar-button-background);
  border-radius: 50px;
}

.navbar_button:hover {
  background: var(--navbar-color);
  color: var(--navbar-button-background);
}

.login_register_link {
  margin-left: 28px;
}

.submenu{
  min-width: max-content;
}
.submenu, .submenu_profile {
  display: none;
  border-radius: 3px;
  background: var(--navbar-submenu-bg-color);
}

.submenu::before, .submenu_profile::before {
  content: '';
  position: absolute;
  background: none;
  top: -25px;
  left: 0;
  width: 100%;
  height: 25px;
}

.submenu_sec:hover .submenu,.submenu_sec:hover  .submenu_profile {
  display: block;
  top: calc(100% + 20px);
  padding: 5px 0;
  z-index: 1;
  min-width: max-content;
  right: 0;
}
.submenu_sec:hover  .submenu_profile{
  padding: 5px 0;
  min-width: max-content;
}

.submenu_profile > li {
  padding: 8.5px 25px 8.5px 10px;
  border-radius: 0;
  transition: 0.4s ease;
  margin: 0 5px 5px 5px;
}

.submenu_profile > li:last-child  {
  border-top: 1px solid #0000001a;
}


.submenu_profile > li > a > p {
  transition: 0.4s ease;
  color: #000;
  font-size: 16px;
}

.submenu_profile > li > a > p svg{
  margin-right: 10px;
  color: #043873;
}

.submenu_profile > li:hover {
  background: #043873;
  border-radius: 5px;

}

.submenu_profile > li:hover > a > p, .submenu_profile > li:hover > a > p svg {
  color: #fff;
}

.submenu > li {
  margin: 0 5px;
  margin-bottom: 5px;
  padding: 5px 5px;
  border-radius: 5px;
  transition: 0.4s ease;
}

.submenu > li > a {
  /* padding: 15px 5px; */
  padding: 0 5px;
  transition: 0.4s ease;
}

.submenu > li > a {
  color: #000;
}

.submenu > li:hover,  .submenu > li:has(.active)  {
  background: #043873;
}

.submenu > li:hover > a, .submenu > li > a.active {
  color: var(--navbar-submenu-bg-color);
}

.submenu_profile > li:last-child, .submenu > li:last-child{
  margin-bottom: 0;
}


.downIposArrow_mobile{
  display: none;
}

.profile_menu_li.active {
  background: #043873;
}

.profile_menu_li.active a p, .profile_menu_li.active a svg{
  color: #fff;
}

@media (max-width: 1200px) {
  .navbar_list > li {
    padding-right: 20px;
  }

  .login_register_link {
    margin-left: 15px;
  }

  .navbar_list > li > a,
  .submenu > li > a {
    font-size: 16px;
  }

  .navbar_list > li.login_register_link {
    padding-right: 0;
  }

  .navbar_button {
    font-size: 16px;
    padding: 10px 25px;
  }
}

@media (max-width: 992px) {
  .navbar_list > li.login_register_link {
    margin-left: 0;
  }

  .navbar_list > li {
    padding-right: 12px;
  }

  .submenu_sec > a > span > img {
    padding-left: 5px;
  }

  .navbar_list > li > a,
  .submenu > li > a {
    font-size: 14px;
  }
  .navbar_button {
    font-size: 14px;
    padding: 7px 15px;
  }
}

@media (max-width: 768px) {
  .submenu_sec .submenu{
    position: static;
    height: 0 !important;
    transition: 0.4s ease;
  }

  /* .navBar .navbar_list > li:hover > a, .navBar .navbar_list > li:not(.login_register_link)> a.active{
    background: #043873;
    color: #fff;
  } */
  .navbar_list > li:not(:has(button)){
    margin-bottom: 15px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  .navbar_list > li:not(:has(button)):not(:has(.submenu)):hover, .navbar_list > li:not(:has(button)):not(:has(.submenu)):has(a.active) {
    background: #043873;
    color: #fff;
  }

  .submenu > li{
    padding: 0 5px;
  }

  .navBar .navbar_list > li:not(.login_register_link) > a::before, .navBar .navbar_list > li:not(.login_register_link) > a.active::before{
    content: unset;
  }

  .submenu_sec:hover .submenu {
    /* top: calc(100% - 10px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    height: 100% !important;
}
  .hamburger {
    display: block !important;
  }
  .downIposArrow{
    display: none;
  }
  .downIposArrow_mobile{
    display: block;
    color: #000;
    font-size: 27px;
    position: relative;
    left: -7px;
    top: -1px;
  }
 .submenu_sec:hover .submenu_profile {
    /* top: 88%; */
    height: 100% !important;
}

.submenu_sec .submenu_profile{
  position: static;
  transition: 0.4s ease;
  height: 0 !important;
}

  .navbar_list {
    position: fixed;
    padding: 10px 15px;
    border-radius: 8px;
    right: -300px;
    display: block;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 300px;
    transition: 0.4s ease;
    background: var(--mobile-navbar-bg-color);
    z-index: 11;
  }

  .navbar_list > li > a {
    color: var(--navbar-mobile-color);
    font-size: 17px;
  }
  .navbar_list.active {
    right: 0;
    /* right: 80px; */
  }

  .navbar_list > li {
    padding-right: 0;
    padding-bottom: 15px;
    max-height: unset !important;
  }

  /* .navBar > div > div {
    padding: 15px 0;
  } */

  .navbar_button {
    color: #fff;
  }

  .profile_menu:hover{
    background: unset !important;
  }
  .profile_menu svg{
    color: #043873;
  }

 

  .profile_menu  div > p.profile_first_last_name:first-child{
    font-weight: 500;
  }
     .profile_menu p{
    color: #000;
  }
}
