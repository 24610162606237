.profile-heading-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #212529;
}

.profile-heading-email {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #1c1b1f;
  opacity: 0.5;
}

.profile-img {
  max-width: 100px;
  max-height: 100px;
  min-width: 100px;
  min-height: 100px;
  top: 49px;
  left: 140px;
  gap: 0px;
  /* opacity: 0px; */
  object-fit: contain;
}

.profile-btn {
  text-align: center;
  width: 93px;
  height: 44px;
  border-radius: 8px;
}

.profileLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  opacity: 0.8;
  color: #212529;
  padding-bottom: 10px;
}

.profile_page_section > div {
  gap: 2%;
}

.profile-img-upload {
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  min-width: 100px;
  min-height: 100px;
  opacity: 0;
}

.profileInputField,
.phoneNumber_profile select,
.phoneNumber_profile input {
  border-radius: 8px;
  background-color: #edeaea;
  border: 2px solid #edeaea;
  height: 52px;
  /* font-family: Inter; */
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #212529;
  outline: none;
  padding: 15px;
}

.phoneNumber_profile{
  overflow: hidden;
}

.profileInputField.editable {
  background: #fff;
  border: 2px solid #edeaea;
}

.phoneNumber_profile select,
.phoneNumber_profile input,
.phoneNumber_profile .PhoneInputCountry {
  border: unset;
}
