
.loader {
    margin: 50px;
  }
  
  .push-pop {
    --duration: .85;
    height: 100px;
    width: 100px;
    position: relative;
    overflow: hidden;
  
    & > div:nth-of-type(1) {
      height: 20px;
      width: 20px;
      position: absolute;
      animation: push-pop-slide calc(var(--duration) * 1s) infinite alternate ease-in-out;
      transform: translate(0, -100%);
      top: 100%;
      left: 0;
  
      &:after {
        animation: push-pop-flip calc(var(--duration) * 1s) infinite alternate ease-in-out;
        background: var(--primary-color);
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  
    & > div:nth-of-type(2) {
      background: var(--primary-color);
      height: 30px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%, -100%);
      width: 20px;
    }
  
    &:after,
    &:before {
      animation: push-pop-pushed calc(var(--duration) * 1s) alternate infinite ease;
      background:var(--primary-color);
      bottom: 0;
      content: '';
      height: 40px;
      position: absolute;
      width: 20px;
    }
  
    &:before {
      animation: push-pop-pushed calc(var(--duration) * 1s) alternate-reverse infinite ease;
      left: 0;
    }
  
    &:after {
      right: 0;
    }
  }
  
  @keyframes push-pop-pushed {
    0%, 72.5% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 100%);
    }
  }
  
  @keyframes push-pop-flip {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    50% {
      transform: translate(0, -80px) rotate(90deg);
    }
    100% {
      transform: translate(0, 0) rotate(180deg);
    }
  }
  
  @keyframes push-pop-slide {
    to {
      transform: translate(0, -100%) translate(80px, 0);
    }
  }
  

  /*.loader {
    width: 100px;
    height: 100px;
    background-size: cover;
    animation: imageLoader 2s infinite steps(5); 
  }
  
  @keyframes imageLoader {
    0% { background-image: url('https://st.depositphotos.com/2274151/4841/i/450/depositphotos_48410095-stock-photo-sample-blue-square-grungy-stamp.jpg'); }
    20% { background-image: url('https://cdn.pixabay.com/photo/2023/01/08/14/22/sample-7705346_640.jpg'); }
    40% { background-image: url('https://cdn.explorecams.com/storage/photos/LEFEikw0MR_1600.jpg'); }
    60% { background-image: url('https://cdn-icons-png.flaticon.com/512/9908/9908191.png'); }
    80% { background-image: url('https://www.alansonsample.com/images/Alanson_Headshot.jpg'); }
    100% { background-image: url('https://st.depositphotos.com/2274151/4841/i/450/depositphotos_48410095-stock-photo-sample-blue-square-grungy-stamp.jpg'); }
  } */