.auth_container {
  min-height: max-content;
  padding: 80px 0 0 0;
  margin-bottom: 30px;
}

.authFormo {
  /* background: #043873; */
  /* margin-bottom: -70px; */
}

.auth_form {
  border-radius: 8px;
  background: #fff;
  padding: 30px 40px;
  /* min-height: 720px; */
}

.auth_title {
  /* font-size: 40px; */
  font-size: 35px;
  font-weight: 600;
  line-height: 40px;
  color: #212529;
  padding-bottom: 10px;
}

.auth_para {
  font-weight: 400;
  line-height: 19.36px;
  color: #212529;
  padding-bottom: 15px;
  font-size: 16px;
}
.auth_delete_submit{
  background-color: #3a4248 !important;
}
.input_class > form > div {
  margin: 0;
}

.input_class {
  padding-bottom: 24px;
}

.input_class > form,
.input_class > form > div {
  width: 100% !important;
}

.inputField input {
  border: 1px solid #79747e3b;
  /* padding: 16.5px 15px; */
  border-radius: 6px;
  padding: 10px 15px;
}

.table_inputField {
  border: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  /* color: #212529; */
  color: #21252993;
}

.label_0_inputField {
  /* transform: translateY(30px); */
  transform: translateY(24px);
}

.label_00_inputField {
  transform: translateY(24px);
}

.label_11_inputField {
  transform: translateY(2px);
  background: #fff;
  padding: 0 5px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.label_00_inputField,
.label_11_inputField {
  left: 85px;
}

.label_1_inputField {
  transform: translateY(2px);
  background: #fff;
  padding: 0 5px;
}

.passwordShow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.checkbox_text {
  font-size: 14px;
}

.forgot_password {
  font-size: 12px;
  min-width: max-content;
  color: #043873;
}

.auth_submit {
  background: #043873;
  border: 1px solid #043873;
  border-radius: 4px;
  padding: 15px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 16.94px;
  transition: 0.4s ease;
  cursor: pointer;
  box-shadow: 0px 4px 15px 0px #0000001c;
}

.auth_submit:hover {
  background: #ffffff;
  color: #043873;
}

.auth_submit_login {
  background: #043873;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 12px 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.94px;
  transition: 0.4s ease;
  cursor: pointer;
  box-shadow: 0px 4px 15px 0px #0000001c;
}

.auth_submit_login:hover {
  background: #043873;
  color: #fff;
}

.auth_submit_view_more {
  background: #043873;
  border: 1px solid #043873;
  border-radius: 6px;
  padding: 12px 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 16.94px;
  transition: 0.4s ease;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.auth_submit_view_more:hover {
  background: #fff;
  color: #043873;
}

.auth_submit_login_check_ipos{
  padding: 13px 20px;
  max-height: max-content;
}

.singupText {
  padding-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  flex-wrap: wrap;
}

.signUp_text {
  color: #043873;
}

.BrokerOtherDetails h3{
  color: #043873;
}

.broker_tabs_ui{
  background: #04387317;
  color:#043873;
  border: 1px solid #04387317;
}

.broker_tabs_ui:hover{
  color : #fff;
  background:#043873;
}

.or_line {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.97px;
}

.error-text_form {
  /* bott */
}

.or_line::before,
.or_line::after {
  content: '';
  position: absolute;
  background: #bfbfbf;
  width: 40%;
  height: 1px;

  top: 50%;
  transform: translateY(-50%);
}

.or_line::before {
  left: 5%;
}

.or_line::after {
  right: 5%;
}

.googleBtn {
  box-shadow: 0px 4px 15px 0px #0000001c;
  padding: 15px 30px;
  border-radius: 8px;
  transition: 0.4s ease;
}

.googleBtn p {
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.68px;
  padding-left: 27px;
  transition: 0.4s ease;
}

.googleBtn:hover {
  color: #fff;
  background: #043873;
}

.phoneNumberLabel{
  padding-bottom: 0 !important;
}

.googleBtn:hover p {
  color: #fff;
}

.signup_card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
}

.PhoneInput {
  padding: 0;
}

.PhoneInput .PhoneInputCountryIcon--border {
  margin-right: 5px;
}

.PhoneInput .PhoneInputCountry {
  border: 1px solid #79747e3b;
  border-radius: 6px;
  padding: 10px;
}

.plusIconAvatar{
  background: #c2deff8a;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  top: 50%;
  left: 50%;
}

.svg_broker{
  max-width: 12px;
  max-height: 12px;
}

.open_demat_account{
  padding-left: 10px;
  padding-right: 10px;
}

.plusIconAvatar svg{
  fill: #043873;
}
.avatar_png:hover .plusIconAvatar{
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) scale(1);
}
.PhoneInput input {
  border: 1px solid #79747e3b;
  border-radius: 6px;
  padding: 10px 15px;
  width: 100%;
  outline: none;
}

.phone_number_text {
  top: 25px;
  left: 75px;
}

.FaThumbsUpBroker{min-width: 18px;}

.brokerImgLogo{
  max-width: 70px;
  min-width: 70px;
  border-radius: 7px;
}

.DeleteCountryCode select{
  pointer-events: none;
}

.DeleteCountryCode select option{
  display: none;
}

.DeleteCountryCode .PhoneInputCountrySelectArrow{
  display: none;
}

.DeleteCountryCode .PhoneInputCountryIcon{
  margin-right: 0;
} 

@media (max-width: 1024px) {
  .auth_title {
    font-size: 30px;
    line-height: 35px;
    padding: 15px 0;
  }

  .auth_para {
    padding-bottom: 20px;
    font-size: 14px;
  }

  .googleBtn {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .auth_form {
    min-height: 0;
  }
}

@media (max-width: 768px) {
  .auth_width {
    width: 100%;
  }

  .auth_container {
    flex-wrap: wrap;
  }

  .auth_form {
    padding: 30px 15px;
  }

  .singup_login_img {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
  }

  .auth_title {
    font-size: 25px;
    line-height: 30px;
  }

  .auth_submit_login {
    background: #043873;
    color: #fff;
  }
  .auth_submit_login:hover {
    background: #fff;
    color: #043873;
  }

  .auth_submit_view_more {
    font-size: 14px;
    line-height: 14px;
}
}


@media(max-width:639px){
  .phone_number_input_part{
    width: 100%;
  }
}