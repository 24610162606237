.Blog-Main-Image {
  max-height: 350px;
  object-fit: cover;
  border-radius: 10px;
}

.fs-28 {
  font-size: 28px;
  line-height: 39.2px;
  color: #1a1a1a;
}

.blog_views {
  color: #7a7a7a;
  line-height: 40px;
  font-size: 14px;
}

.news_img_block{
  display: block;
  margin-left: auto;
  margin-left: auto;
  width: 100%;
}

.popular_posts {
  color: #42526e;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.8399999737739563px;
}

.popular_product {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.7200000286102295px;
  color: #42526e;
  top: -7px;
  max-width: 250px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.popular_text {
  line-height: 21px;
  color: #172b4d;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.popular-post-image {
  max-height: 104px;
  min-height: 104px;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.news_title_detail {
  transition: 0.4s ease;
}

.news_title_detail:hover {
  color: #043873;
}

.collapse-css-transition.--c-collapsed:after {
  content: "";
}
.collapse-css-transition:after {
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
  /* opacity: 0;
  opacity: 1; */
  position: absolute;
  top: -85px;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.8)
  );
}

@media (max-width: 1023px) {
  .popular-post-image {
    min-height: 100px;
    max-width: 300px;
    min-width: 100%;
  }

  .popular_border > div:first-child {
    width: 25%;
    padding-right: 5px;
  }
  .popular_border > div:last-child {
    width: 75%;
  }

  .Blog-Main-Image {
    max-height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
}
