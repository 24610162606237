


/* hemang page css */


.react-datepicker-popper{
    z-index: 4000 !important;
  }
  
  .newTabIpoDetail .tabs-container {
    max-width: 75rem;
    margin: 0 auto;
    padding: 0 2rem;
  }
  
  .newTabIpoDetail .tabs-wrapper {
    padding: 3rem 0;
  }
  
  .newTabIpoDetail .tab-menu {
    display: flex;
    justify-content: space-between;
    border-bottom: 1.3px solid #d4d4d4;
  }
  
  .newTabIpoDetail .tab-menu-link {
    flex: 1;
    padding: 1rem;
    text-align: center;
    font-weight: 600;
    color: var(--color-blue-gray);
    background: var(--color-white);
    cursor: pointer;
    border-bottom: 2.5px solid transparent;
    transition: all 0.3s ease;
  }
  
  .newTabIpoDetail .tab-menu-link.is-active {
    color: #fff;
    border-bottom: 2.5px solid var(--color-red-light);
    background: #000;
  
  }
  
  .newTabIpoDetail .tab-bar {
    padding: 2.5rem 0;
    background: var(--color-white);
  }
  
  .newTabIpoDetail .tab-bar-content {
    display: none;
    transition: all 0.3s ease;
  }
  
  .newTabIpoDetail .tab-bar-content.is-active {
    display: block;
  }
  
  .newTabIpoDetail .texts .title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--color-blue-dark);
  }
  
  .newTabIpoDetail .texts .paragraph {
    margin-top: 0.5rem;
    color: var(--color-blue-gray);
  }
  
  .newTabIpoDetail .tab-menu-link.is-active{
    background : #043873 !important
  }