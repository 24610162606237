.apply_now_sec{
    overflow: unset;
}

.apply_now_part{
    padding-top: 35px;
    padding-bottom: 35px;
}

.applynowInput{
    box-shadow:  rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    min-height: 40px;
    padding: 5px 10px;
    line-height: 100%;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.applynowInput:focus{
    outline: unset;
}

.account_add_modal{
    position: fixed;
    width: 50%;
    min-width: 300px;
    margin: auto;
    background: #fff;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
    padding: 15px 15px 30px 15px;
    max-height: 70vh;
    overflow-y: auto;
}

.edit_modal_apply{
    padding-bottom: 15px;
}

.close_modal_add_account{
    position: absolute;
    right: 10px;
    top: 10px;
}

.account_add_modal:focus{
    outline: unset;
}

.accountTypeRadio[type="radio"]{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    display: block; 
}

.add_account_form input[type="text"]{
    width: 100%;
    box-shadow:  rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    border-radius: 7px;
    min-height: 40px;
}

.add_account_form input[type="text"]:focus{
    outline: none;
}

.apply_form_check > label{
    cursor: pointer;
}

.apply_form_check > label > span > div{
    border-color: #043873 !important;
}

.apply_form_check > label > span > div > svg{
    background: #043873;
    color: #fff;
}

.apply_now_select {
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.apply_now_select  > div{
    border: unset;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    min-height: 40px;
    cursor: pointer;
}

.apply_now_select  > div> div> div{
    font-size: 14px;
}

.apply_now_select > div .css-pogzpp-loadingIndicator{
    display: none !important;
}

.apply_now_select > div:has(input[name="selectedAccount"]){
    display: none;
}

.editIpoText{
    font-size: 16px;
}

.cancel_btn_edit_apply_modal{
    background: #fff;
    color: #043873;
}

.cancel_btn_edit_apply_modal:hover{
    background: #043873;
    color: #fff;
}